import { navigate } from "gatsby-link";
import {
  Box,
  Image,
  ResponsiveContext,
  TextInput,
  Button,
  Text,
  Heading,
  DateInput,
} from "grommet";
import React, { useState } from "react";
import Layout from "../components/elements/layout";
import mainLogo from "../images/logo.png";
import { saveProfile } from "../services/login_service";
import { getUser, saveAuth } from '../services/storage_service';
import moment from 'moment';
import ToggleButton from "../components/elements/toggle_button/toggle_button";

const ProfilePage = ({ size, logo }) => {
  const [civil, setCivil] = useState(getUser()?.civilite);
  const [nom, setNom] = useState(getUser()?.nom);
  const [prenom, setPrenom] = useState(getUser()?.prenom);
  const [birthday, setBirthday] = useState(getUser()?.birthday);
  const [phone, setPhone] = useState(getUser()?.phone);
  const [address, setAddress] = useState(getUser()?.address);
  const [postcode, setPostcode] = useState(getUser()?.zip);
  const [city, setCity] = useState(getUser()?.city);
  const [country, setCountry] = useState(getUser()?.country);
  const [iban, setIban] = useState(getUser()?.iban);
  const [bic, setBic] = useState(getUser()?.bic);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const doChangeProfile = async () => {

    if (!civil || civil.length <= 0) {
      setError("Votre civilité non valid");
      return;
    }
    if (!nom || nom.length <= 0) {
      setError("Votre nom non valid");
      return;
    }
    if (!prenom || prenom.length <= 0) {
      setError("Votre prénom non valid");
      return;
    }
    if (!birthday) {
      setError("Votre date de naissance non valid");
      return;
    }
    if (!phone || phone.length <= 0) {
      setError("Votre téléphone non valid");
      return;
    }
    if (!address || address.length <= 0) {
      setError("Votre adresse non valid");
      return;
    }

    if (!postcode || postcode.length <= 0) {
      setError("Votre code postal non valid");
      return;
    }
    if (!city || city.length <= 0) {
      setError("Votre ville non valid");
      return;
    }
    if (!country || country.length <= 0) {
      setError("Votre pays non valid");
      return;
    }


    setError("");
    setLoading(true);
    saveProfile(getUser().id, {
      nom, prenom, civilite: civil, birthday: moment(birthday).toISOString(), phone, address, city, zip: postcode, country, iban, bic
    })
      .then((res) => {
        const s = res.data;
        if (s.prenom) {
          alert(
            "Votre profil est bien enregistré."
          );
          // save data
          let _user = window.localStorage.getItem('user');
          let _userJson = JSON.parse(_user);
          _userJson.user = res.data;
          saveAuth(_userJson);
          navigate("/home");
        } else {
          setError("Erreur! Merci de vérifier les données remplis.");
          setLoading(false);
        }
      })
      .catch((err) => {
        setError("Erreur! Merci de vérifier les données remplis.");
        setLoading(false);
      });
  };

  const inputStyle = {
    background: "#EDEDED",
    fontSize: 16,
    borderRadius: 10,
    border: "none",
  };
  return (
    <Layout
      style={{ justifyContent: "center", alignContent: "center" }}
      hideHeader="true"
      hideFooter="true"
    >
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            justify="center"
            overflow="auto"
            direction="column"
            alignContent="center"
            alignSelf="center"
          >
            <Box
              width="medium"
              alignSelf="center"
              justify="center"
              align="center"
              alignContent="center"
            >
              <Image src={logo || mainLogo} margin="small" />
              <Heading
                level="3"
                style={{ color: "#707070" }}
                textAlign="center"
              >
                Première connexion
              </Heading>
              <Text
                level="5"
                style={{ color: "#707070", marginBottom: 20 }}
                textAlign="center"
              >
                <b>Bienvenue ! </b>
                <br />
                Il s’agit de votre première connexion.<br />Merci de compléter ton profil.
              </Text>
              <Text
                size="small"
                style={{ color: "#dc3545", marginBottom: 20, background: 'yellow', width: "100%" }}
                textAlign="center"
              >
                <b>{error}</b>
              </Text>
              <Box width="large" gap="small">
                <ToggleButton
                  groups={[
                    {
                      label: "Madame",
                      selected: civil === "Madame",
                      onClick: () => setCivil("Madame")
                    },
                    {
                      label: "Monsieur",
                      selected: civil === "Monsieur",
                      onClick: () => setCivil("Monsieur")
                    },
                  ]}
                />
                <TextInput
                  value={nom}
                  placeholder="Votre nom *"
                  onChange={(event) => setNom(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  value={prenom}
                  placeholder="Votre prénom *"
                  onChange={(event) => setPrenom(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  placeholder="Votre email *"
                  disabled={true}
                  value={getUser()?.email}
                  style={inputStyle}
                />
                <DateInput
                  placeholder="Date de naissance *"
                  onChange={(event) => setBirthday(event.value)}
                  format="dd/mm/yyyy"
                  min={"01/01/1900"}
                  value={birthday}
                  calendarProps={{
                    locale: "fr-FR",
                    size: "small"
                  }}
                />
                <TextInput
                  value={phone}
                  placeholder="Téléphone *"
                  onChange={(event) => setPhone(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  value={address}
                  placeholder="Adresse principale *"
                  onChange={(event) => setAddress(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  value={postcode}
                  placeholder="Code postal *"
                  onChange={(event) => setPostcode(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  value={city}
                  placeholder="Ville *"
                  onChange={(event) => setCity(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  value={country}
                  placeholder="Pays *"
                  onChange={(event) => setCountry(event.target.value)}
                  style={inputStyle}
                />
                <Text>Votre compte pour percevoir votre rémunération :</Text>
                <TextInput
                  placeholder="IBAN"
                  onChange={(event) => setIban(event.target.value)}
                  style={inputStyle}
                  value={iban}
                />
                <TextInput
                  value={bic}
                  placeholder="BIC"
                  onChange={(event) => setBic(event.target.value)}
                  style={inputStyle}
                />

                <Box width="medium" alignSelf="center" style={{marginBottom: 50}}>
                  <Button
                    primary
                    label="Valider mon profil"
                    onClick={doChangeProfile}
                    disabled={loading}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  );
};

export default ProfilePage;
